<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('transfers') | capitalize }} . {{ $t('transfer number') }} {{ $route.params.id }}
                </strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn v-if="selectedRow?.some(({ item_code }) => item_code)" width="120" height="34" color="blue4" class="me-1"
                outlined @click="printBarcode(null)" depressed>
                <v-icon dark left>mdi-cloud-print-outline</v-icon> {{ $t("print barcode") }}
              </v-btn>
              <v-btn color="transparent" class="error--text mx-2" :width="120" depressed
                @click="$router.push('/transfers')">
                <v-icon size="14" class="mt-1 mx-2">mdi-arrow-right</v-icon>
                {{ $t('back') }}
              </v-btn>
              <v-btn color="red11" class="error--text mx-2" :loading="isDeleteLoading" :width="120" depressed
                @click="deleteDialog = true">
                <v-icon size="14" left>mdi-delete</v-icon>
                {{ $t('delete') }}
              </v-btn>
              <v-btn color="primary" :width="120" :disabled="!(master.transfer_status == 1)" depressed @click="edit">
                {{ $t('edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="pa-0">
          <ControlSummery :summery="summery" :loading="isLoading">
            <template v-slot:value>
              <v-chip color="red12" class="red1--text font-weight-bold" v-if="master.transfer_status == 1" small>
                {{ $t('not received') }}</v-chip>
              <v-chip color="green12" class="green1--text font-weight-bold" v-if="master.transfer_status == 2" small>
                {{ $t('received') }}</v-chip>
              <v-chip color="yellow10" class="yellow1--text font-weight-bold" v-if="master.transfer_status == 3" small>
                {{ $t('rejected') }}</v-chip>
            </template>
          </ControlSummery>
        </v-col>

        <v-col cols="12" sm="12" class="py-0" v-if="details?.length">
          <DynamicTable :isLoading="isLoading" classes="shadow-none" :searchByDateRange="false" :showHeader="false"
            :viewTitle="false" :data="details" :header="tableHeader" :parentSelectedRow="selectedRow"
            :selectAll="selectAll" :viewSelectAll="true" :checkboxMethod="checkboxMethod" :selectRow="true">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'actions'">
                <v-btn @click="remove(row)" color="error" x-small icon fab>
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>

      </v-row>


      <DeleteConfirmation :dialog="deleteDialog" :item="null" :backValueMethod="deleteMethod" />
      <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/transfers" />
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import ControlSummery from "@/components/ui/ControlSummery.vue";
import DeleteConfirmation from "@/components/modals/DeleteConfirmation.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
export default {
  name: "TransfersView",

  data: () => ({
    isLoading: false,
    exitDialog: false,
    isDeleteLoading: false,
    deleteDialog: false,
    master: Object,
    details: [],
    selectedRow: [],
    summery: [],
    responseData: Object,
    type: 'cash',
  }),
  components: {
    DynamicTable,
    ControlSummery,
    DeleteConfirmation,
    ExitConfirmationModal
  },
  watch: {

  },
  computed: {
    tableHeader() {
      const hasDiamond = this.details.some((row) => row.category_id == 25)
      const hasStone = this.details.some((row) => row.category_id == 26)
      const hasSilver = this.details.some((row) => row.category_id == 925)
      let header = [
        { text: "serial", key: "item_code", type: 'text', classes: "", },
        { text: "type", key: "category_title", type: 'text', classes: "" },
        { text: "item by caliber", key: "sub_category_title", type: 'text', classes: "" },
        { text: "gold weight", key: "gold_weight", type: 'float', classes: "" },
        { text: "caliber price", key: "caliber_price", type: 'float', classes: "" },
        { text: "", key: "actions", hideInPrint: false, type: 'slot', classes: "", },
      ];
      if (hasDiamond) {
        header.splice(4, 0,
          { text: "diamond weight", key: "diamond_weight", type: 'float', classes: "" },
          { text: "shape", key: "shape_title", type: 'text', classes: "" },
          { text: "color", key: "color_title", type: 'text', classes: "" },
          { text: "clarity", key: "clarity_title", type: 'text', classes: "" },
          { text: "quality", key: "quality_title", type: 'text', classes: "" },
        )
      }
      if (hasStone) {
        header.splice(4, 0,
          { text: "stone weight", key: "stone_weight", type: 'float', classes: "" },
        )
      }
      if (hasSilver) {
        header.splice(4, 0,
          { text: "silver weight", key: "silver_weight", type: 'float', classes: "" },
        )
      }


      return header
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {

    },

    getData() {
      this.isLoading = true;
      let endPoint = '';
      switch (this.$router.currentRoute.query.type) {
        case 'cash':
          this.type = "cash";
          endPoint = `money_transfer/${this.$route.params.id}`
          break;
        case 'metal':
          this.type = "metal";
          endPoint = `metal_transfer/${this.$route.params.id}`
          break;
        default:
          this.$router.push(`/transfers`)
          break;
      }
      this.$api.GET_METHOD(endPoint)
        .then((response) => {
          this.isLoading = false;
          this.responseData = response.data;
          if (this.type == 'cash') {
            this.master = response.data.data || Object;
            this.details = [];
            this.summery = [
              {
                label: '',
                value: '',
                class: '',
                type: 'slot',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'action date',
                value: response.data.data.action_date,
                type: response.data.data.action_date ? 'date' : 'text',
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'response date',
                value: response.data.data.response_date,
                type: response.data.data.response_date ? 'date' : 'text',
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              // {
              //   label: 'transfer type',
              //   value: response.data.data.transfer_type == 1 ? this.$i18n.t('bank transfer') : this.$i18n.t('branch transfer'),
              //   class: '',
              //   cols: null, sm: null, md: null, lg: 4, xl: 4,
              // },
              {
                label: this.$i18n.t('from'),
                value: response.data.data.from_bank_name ? response.data.data.from_bank_name : response.data.data.from_branch_name,
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: this.$i18n.t('to'),
                value: response.data.data.to_bank_name ? response.data.data.to_bank_name : response.data.data.to_branch_name,
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'amount',
                value: response.data.data.amount,
                type: 'float',
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'description',
                value: response.data.data.description,
                type: 'text',
                class: '',
                cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
              },

            ];
          } else {
            this.master = response.data.master || Object;
            this.details = response.data.details || [];
            this.responseData = response.data;
            this.summery = [
              {
                label: '',
                value: '',
                class: '',
                type: 'slot',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'action date',
                value: response.data.master.action_date,
                type: response.data.master.action_date ? 'date' : 'text',
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'response date',
                value: response.data.master.response_date,
                type: response.data.master.response_date ? 'date' : 'text',
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'branch',
                value: response.data.master.to_branch_name,
                class: '',
                cols: null, sm: null, md: null, lg: 4, xl: 4,
              },
              {
                label: 'description',
                value: response.data.master.description,
                type: 'text',
                class: '',
                cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
              },

            ];
          }
        })

    },
    edit() {
      if (this.type == 'cash') {
        this.$router.push({
          name: "CashTransfer",
          params: { master: this.master, details: [], type: this.type, responseData: this.responseData },
        });
      } else {
        this.$router.push({
          name: "MetalTransfer",
          params: { master: this.master, details: this.details, type: this.type, responseData: this.responseData },
        });
      }
    },
    deleteMethod(status) {
      if (status == true) {
        this.isDeleteLoading = true;
        this.deleteDialog = false
        this.$api.POST_METHOD(`${this.type == 'cash' ? 'money_transfer' : 'metal_transfer'}/${this.master.id}`, { _method: 'delete' }).then((response) => {
          this.isDeleteLoading = false
          if (response.check) {
            this.$router.push('/transfers');
          }
        })
      } else {
        this.deleteDialog = false
      }
    },
    printBarcode(code) {
      console.log(code);
      const rowIDS = this.details.map(({ item_code }) => item_code);
      window.open(`${this.$api.serverUrl}print_barcode?id=${rowIDS}&type=print&clientID=${this.$store.state.clientID}`, '_blank');
    },
    selectAll(status) {
      this.selectedRow = status ? this.details : []
    },
    checkboxMethod(rows) {
      this.selectedRow = rows
    },
  },
};
</script>

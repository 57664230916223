<template>
  <section>
    <v-container fluid>

      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0 mb-6">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $t('gold permission') | capitalize }} . {{ $t('create gold payment permission') }}</strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="white" class="gray2--text shadow" v-if="isEdit" :width="120" depressed @click="printMethod">
                {{ $t('print') }}
                <img class="mx-2" height="20" src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
              </v-btn>
              <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
                {{ $t('cancel') }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" :disabled="!(valid && saveIsValid)" :width="120" :loading="isSaveLoading" depressed
                @click="save">
                {{ $t('save') }}
                <v-icon right>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Form -->
      <v-form ref="form" v-model="valid" class="pa-3">
        <v-card class="backgroundW shadow pa-4 py-7">
          <v-row align="start" justify="space-between">
            <v-col cols="8" class="py-2">
              <v-row align="center">

                <GenericInput type="date" :solo="true" :dense="true" classes="rounded-lg" :value="form.action_date"
                  @input="form.action_date = $event" label="action date" :maxDate="today" :required="false"
                  :hide-details="true" :cols="[12, 6, 4]"></GenericInput>
                <GenericInput type="select" :lookups="types" :value="form.action_type"
                  @input="form.action_type = $event" label="type" :multi="false" :required="true" selected_label="name"
                  selected_prop="id" :isLoading="false" :cols="[12, 6, 4]" />

                <GenericInput v-if="form.action_type == 1" type="select" :lookups="suppliers" :value="form.supplier_id"
                  @input="form.supplier_id = $event" label="supplier" :multi="false" :required="true"
                  selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 4]" />

                <GenericInput v-if="form.action_type == 2" type="select" :lookups="customers" :value="form.customer_id"
                  @input="form.customer_id = $event" label="customer" :multi="false" :required="true"
                  selected_label="name" selected_prop="id" :isLoading="false" :cols="[12, 6, 4]" />

                <GenericInput v-if="form.action_type == 3" type="select" :lookups="treasuryGold"
                  :value="form.treasury_gold_id" @input="form.treasury_gold_id = $event" label="treasury gold"
                  :multi="false" :required="true" selected_label="name" selected_prop="id"
                  :disableItem="{ key: 'id', value: form.credit_treasury_gold_id }" :cols="[12, 6, 4]" />

                <GenericInput v-if="form.action_type == 4" type="select" :lookups="account_trees"
                  :value="form.account_tree_id" @input="form.account_tree_id = $event" label="account" :multi="false"
                  :required="true" selected_label="name" selected_prop="id" :isLoading="false" />

                <GenericInput type="select" :lookups="treasuryGold" :value="form.credit_treasury_gold_id"
                  @input="form.credit_treasury_gold_id = $event" label="credit treasury gold" :multi="false"
                  :required="true" selected_label="name" selected_prop="id"
                  :disableItem="{ key: 'id', value: form.treasury_gold_id }" :cols="[12, 6, 4]" />

                <GenericInput type="text" :keydownEnter="searchByBarcode" :dense="true" classes="rounded-lg"
                  :value="code" @input="code = $event" label="barcode" :isLoading="isSearchLoading" :required="false"
                  :hide-details="true" :cols="[12, 6, 4]" icon="mdi-barcode">
                </GenericInput>
                <v-col cols="12" md="6" lg="4" class="d-flex pb-0 mt-2">
                  <v-btn color="primary" width="48%" :loading="isSearchLoading" @click="searchByBarcode"
                    :disabled="!code" :height="39" depressed>
                    <v-icon left>mdi-magnify</v-icon>
                    {{ $t('search') }}
                  </v-btn>
                  <v-divider vertical class="mx-2" style="height: 37px;"></v-divider>
                  <v-btn color="blue2" @click="addDetail" class="white--text" width="48%" :height="39" depressed>
                    {{ $t('add manual line') }}
                  </v-btn>
                </v-col>
                <GenericInput type="switch" v-if="!isEdit" :value="form.is_diamond" @input="form.is_diamond = $event"
                  label="diamond permission" :multi="false" :required="false" :isLoading="false" :cols="[12, 6, 4]" />


              </v-row>

            </v-col>
            <GenericInput type="textarea" :rowsNumber="4" :value="form.description" @input="form.description = $event"
              label="description" :required="false" :isLoading="false" :cols="[12, 12, 4]" />


          </v-row>
        </v-card>
      </v-form>

      <DynamicTable :showHeader="false" :isLoading="isLoading" :data="computedDetails || []" :header="tableHeader"
        :showGroupHeader="false" :groupHeader="[]" :tableHeight="$store.state.Settings.windowSize.y - 320">
        <template v-slot:td="{ row, header, index }">
          <GoldPermissionDetail :pageIsEdit="isEdit" :detail="row" :header="header" :index="index"
            :tableRows="computedDetails" :categories="categories" :sub_categories="subcategories" :addDetail="addDetail"
            :removeDetail="removeDetail" :colors="colors" :shapes="shapes" :clarities="clarities" :qualities="qualities"
            :updateDetail="updateDetail" :duplicateDetail="duplicateDetail" :is_diamond="form.is_diamond" />
        </template>
      </DynamicTable>

      <VoucherMetal :type="2" :printAction="() => print = false" :print="print" :printData="[printStaticData]"
        :printStaticData="printStaticData" />

      <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false"
        exit_to="/permission/gold-payment-permission" />
    </v-container>
  </section>
</template>



<script>
import GenericInput from '@/components/GenericInput.vue';
import ExitConfirmationModal from '@/components/modals/ExitConfirmationModal.vue';
import VoucherMetal from '../VoucherMetal.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import GoldPermissionDetail from '../GoldPermissionDetail.vue';
export default {
  name: "GoldPermissionCreate",
  data: () => ({
    isSaveLoading: false,
    isSearchLoading: false,
    code: null,
    exitDialog: false,
    isLoading: false,
    isEdit: false,
    valid: false,
    today: null,
    print: false,
    summery: [],
    printStaticData: Object,
    // lockups
    account_trees: [],
    categories: [],
    suppliers: [],
    items: [],
    customers: [],
    types: [],
    tableRows: [],
    treasuryGold: [],
    deletedDetails: [],
    form: {
      is_diamond: 0,
      action_type: 1,
      sub_category_id: null,
      credit_account_tree_id: null,
      treasury_gold_id: null,
      credit_treasury_gold_id: null,
      account_tree_id: null,
      category_id: null,
      action_date: null,
      description: null,
      supplier_id: null,
      customer_id: null,
      items: [],
    },
    clarities: [],
    shapes: [],
    colors: [],
    qualities: [],
  }),
  components: {
    GenericInput,
    ExitConfirmationModal,
    VoucherMetal,
    DynamicTable,
    GoldPermissionDetail
  },
  computed: {
    saveIsValid() {
      if (this.computedDetails.length) {
        return this.computedDetails.at(0).sub_category_id && (this.computedDetails.at(0).weight || this.computedDetails.at(0).category_id == 50)
      } else {
        return false;
      }
    },
    hasCategory() {
      return (CATEGORY) => this.computedDetails.some(item => +item.category_id == +CATEGORY)
    },
    subcategories() {
      if (this.form.is_diamond) {
        return this.items.filter(item => +item.category_id == 25)
      } else {
        return this.items.filter(item => +item.category_id !== 25)
      }
    },
    tableHeader() {
      let header = []
      if (this.form.is_diamond) {
        header.push(
          { text: "item description", key: "sub_category_id", type: 'slot', classes: "", width: '225' },
          { text: "factor (4)", key: "factor", type: 'slot', classes: "", width: '80' },
          { text: "gold (G)", key: "gold_weight", type: 'slot', classes: "", width: '120' },
          { text: "diamond1 (D1)", key: "weight", type: 'slot', classes: "", width: '120' },
          { text: "diamond2 (D2)", key: "package_weight", type: 'slot', classes: "", width: '100' },
          { text: "stone (ST)", key: "stone_weight", type: 'slot', classes: "", width: '100' },
          { text: "cost price", key: "price", type: 'slot', classes: "", width: '100' },
          { text: "sell price", key: "sell_price", type: 'slot', classes: "", width: '100' },
          { text: "minimum sell price", key: "min_sell_price", type: 'slot', classes: "", width: '100' },
          { text: "supplier barcode", key: "supplier_code", type: 'slot', classes: "", width: '120' },
          { text: "gold", key: "caliber_id", type: 'slot', classes: "", width: '120' },
          { text: "actions", key: "actions", type: 'slot', hideInPrint: true, classes: "", width: '50' }
        )
      }
      else {
        header.push(
          { text: "item description", key: "sub_category_id", type: 'slot', classes: "", width: '225' },
          { text: "weight", key: "weight", type: 'slot', classes: "", width: '120' },
          { text: "weight in 24", key: "weight_in_24", type: 'float', classes: "", width: '120' },
          { text: "wages", key: "wages", type: 'slot', classes: "", width: '120' },
          { text: "total wages", key: "total_wages", type: 'text', classes: "", width: '120' },
          { text: "actions", key: "actions", type: 'slot', hideInPrint: true, classes: "", width: '50' })
      }
      if (this.hasCategory(50)) {
        header.splice(header.length - 1, 0, { text: "pieces count", key: "pieces_count", type: 'slot', width: '80' })
      }
      return header
    },
    computedDetails() {
      this.tableRows.forEach(details => {
        details.weight = details.weight || 1;
        details.total_wages = +details.wages * +details.weight;
        if (details.category_id <= 24 && details.category_id >= 18) {
          details.weight_in_24 = this.$global.goldCalibration(details.category_id, 24, details.weight);
          details.gold_weight = 0;
          details.shape_id = 0;
          details.color_id = 0;
          details.quality_id = 0;
          details.clarity_id = 0;
          details.caliber_id = 0;
        } else {
          details.weight_in_24 = this.$global.goldCalibration(details.caliber_id, 24, details.gold_weight);
          details.caliber_id = details.caliber_id || 18;
          details.wages = 0;
        }
      });
      return this.tableRows
    },
  },
  watch: {
    'form.action_type'() {
      switch (this.form.action_type) {
        case 1: this.form.customer_id = null; this.form.treasury_gold_id = null; this.form.account_tree_id = null; break;
        case 2: this.form.supplier_id = null; this.form.treasury_gold_id = null; this.form.account_tree_id = null; break;
        case 3: this.form.customer_id = null; this.form.supplier_id = null; this.form.account_tree_id = null; break;
        case 4: this.form.customer_id = null; this.form.supplier_id = null; this.form.treasury_gold_id = null; break;
        default: break;
      }
    },
    'form.sub_category_id'() {
      if (this.form.sub_category_id) {
        this.form.category_id = +this.$global.FilterArrayOfObjectElement(this.items, 'sub_category_id', this.form.sub_category_id, 'category_id');
      } else {
        this.form.category_id = null;
      }
    },
    'form.is_diamond'() {
      if (!this.isEdit) {
        this.tableRows = []; this.addDetail();
      }
    },
    'form.weight'() {
      this.form.total_wages = +this.form.wages * +this.form.weight;
    },
    'form.wages'() {
      this.form.total_wages = +this.form.wages * +this.form.weight;
    },

    print() {
      if (this.print) {
        setTimeout(() => {
          this.print = false
        }, 2000);
      }
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      document.title = `${this.$i18n.t("Qarat - ")}${this.$global.FilterPermissions("02-015").sub_title}`;
      this.today = this.$global.GetCurrentDate();
      this.form.action_date = this.$global.GetCurrentDate();
      this.types = [
        {
          id: 1,
          name: this.$i18n.t('supplier')
        },
        {
          id: 2,
          name: this.$i18n.t('customer')
        },
        {
          id: 3,
          name: this.$i18n.t('treasury gold')
        },
        {
          id: 4,
          name: this.$i18n.t('account')
        },
      ]
    },
    getData() {
      if (this.$route.params.id) {
        this.isEdit = true;
        this.isLoading = true;
        this.$api.GET_METHOD(`payment_gold_permission/${this.$route.params.id}`).then((response) => {
          this.isLoading = false;
          if (response.check) {
            const { supplier_id, customer_id, treasury_gold_id, account_tree_id } = response?.data?.data || {}
            response.data.data.action_type = supplier_id ? 1 : customer_id ? 2 : treasury_gold_id ? 3 : account_tree_id ? 4 : 0;
            this.form = response.data.data;
            // Lockups
            this.account_trees = response.data.account_trees || [];
            this.categories = response.data.categorys || [];
            this.suppliers = response.data.suppliers || [];
            this.customers = response.data.customers || [];
            this.treasuryGold = response.data.treasury_gold || [];
            this.tableRows = response.data.details || [];
            let grouped_items = []
            const groupByCategory = response.data.items.reduce((group, category) => {
              const { category_id } = category;
              group[category_id] = group[category_id] ?? [];
              group[category_id].push(category);
              return group;
            }, {});
            Object.keys(groupByCategory).forEach(key => {
              grouped_items.push({
                category_title: groupByCategory[key][0].category_title,
                items: groupByCategory[key],
              })
            })
            let new_list = []
            Object.keys(grouped_items).forEach(key => {
              new_list.push({ header: grouped_items[key].category_title })
              new_list.push(...grouped_items[key].items)
              new_list.push({ divider: true })
            });
            this.items = new_list || [];

            const supplier = response.data.suppliers.find((supplier) => supplier.id === response.data.data.supplier_id) || Object
            const customer = response.data.customers.find((customer) => customer.id === response.data.data.customer_id) || Object
            response.data.data.supplier_vat_register_number = supplier.vat_register_number;
            response.data.data.supplier_mobile = supplier.mobile;
            response.data.data.customer_vat_register_number = customer.vat_register_number;
            response.data.data.customer_mobile = customer.mobile;
            this.printStaticData = { ...response.data.data };
            this.$refs.form.validate();
          }
        })
      }
      else {
        this.isLoading = true;
        this.$api.GET_METHOD(`payment_gold_permission/create`).then((response) => {
          this.isLoading = false;
          if (response.check) {
            // Lockups
            this.categories = response.data.categorys || [];
            this.suppliers = response.data.suppliers || [];
            this.customers = response.data.customers || [];
            this.account_trees = response.data.account_trees || [];
            this.treasuryGold = response.data.treasury_gold || [];
            let grouped_items = []
            const groupByCategory = response.data.items.reduce((group, category) => {
              const { category_id } = category;
              group[category_id] = group[category_id] ?? [];
              group[category_id].push(category);
              return group;
            }, {});
            Object.keys(groupByCategory).forEach(key => {
              grouped_items.push({
                category_title: groupByCategory[key][0].category_title,
                items: groupByCategory[key],
              })
            })
            let new_list = []
            Object.keys(grouped_items).forEach(key => {
              new_list.push({ header: grouped_items[key].category_title })
              new_list.push(...grouped_items[key].items)
              new_list.push({ divider: true })
            });
            this.items = new_list || [];
            this.addDetail();
          }
        })
      }
    },
    save() {
      this.form.items = this.computedDetails.filter((detail) => detail.sub_category_id && detail.weight);
      this.isSaveLoading = true;
      if (this.isEdit == true) {
        this.form._method = 'PUT';
        this.$api.POST_METHOD(`payment_gold_permission/${this.form.id}`, this.form).then((response) => {
          this.isSaveLoading = false;
          if (response.check) {
            this.printDeletedBarCodes();
            this.$router.push('/permission/gold-payment-permission');
          }
        })
      }
      else {
        if (this.$refs.form.validate()) {
          this.$api.POST_METHOD(`payment_gold_permission`, this.form).then((response) => {
            this.isSaveLoading = false;
            if (response.check) {
              this.$router.push('/permission/gold-payment-permission');
            }
          })
        }
      }
    },
    deleteMethod(status) {
      this.deleteDialog = false
      if (status == true) {
        this.isLoading = true
        this.$api.POST_METHOD(`payment_gold_permission/${this.form.id}`, { _method: 'delete' }).then(() => {
          this.$router.push('/permission/gold-payment-permission');
          this.isLoading = false
        })
      }
    },
    printMethod() {
      this.print = true;
      setTimeout(() => {
        this.print = false;
      }, 1000);
    },
    addDetail() {
      this.tableRows.push({
        d_id: 0,
        barcode_id: 0,
        category_id: 0,
        sub_category_name: null,
        sub_category_id: 0,
        weight: 1,
        weight_in_24: 1,
        wages: 0,
        total_wages: 0,
        gold_weight: 0,
        shape_id: 0,
        color_id: 0,
        quality_id: 0,
        clarity_id: 0,
        caliber_id: 0,
        factor: this.form.is_diamond ? 4 : 0,
        min_sell_price: 0,
        package_weight: 0,
        sell_price: 0,
        supplier_code: 0,
        stone_weight: 0,
        pieces_count: 1,
      });
    },
    removeDetail(index) {
      this.deletedDetails.push({ ...this.tableRows[index] })
      if (this.tableRows.length == index + 1) {
        this.tableRows[index] = {
          d_id: 0,
          barcode_id: 0,
          category_id: 0,
          sub_category_name: null,
          sub_category_id: 0,
          weight: 1,
          weight_in_24: 1,
          wages: 0,
          total_wages: 0,
          gold_weight: 0,
          shape_id: 0,
          color_id: 0,
          quality_id: 0,
          clarity_id: 0,
          caliber_id: 0,
          factor: this.form.is_diamond ? 4 : 0,
          min_sell_price: 0,
          package_weight: 0,
          sell_price: 0,
          supplier_code: 0,
          stone_weight: 0,
          pieces_count: 1,
        }
      } else {
        this.tableRows.splice(index, 1);
      }
      this.tableRows = [...this.tableRows]
    },
    updateDetail(detail, index) {
      this.tableRows[index] = detail
      this.tableRows = [...this.tableRows]
    },
    duplicateDetail(row, index) {
      if (row.sub_category_id) {
        let newDetail = { ...row };
        newDetail.d_id = 0
        this.tableRows.splice(index, 0, newDetail)
      }
    },
    printDeletedBarCodes() {
      let barcodeIDS = this.deletedDetails.filter(row => row.d_id && row.barcode_id)?.map(row => row.barcode_id)
      if (barcodeIDS.length)
        window.open(`${this.$api.serverUrl}print_barcode?id=${barcodeIDS.toString()}&type=print&clientID=${this.$store.state.clientID}`, '_blank')
    },
    searchByBarcode() {
      if (this.tableRows.some((item) => item.barcode_id == this.code) == true) {
        this.$api.ShowAlert("info", this.$i18n.t("item is used"), null)
      } else {
        this.isSearchLoading = true;
        this.$api.GET_METHOD(`search_item?code=${this.code}`).then((response) => {
          this.isSearchLoading = false;
          if (response.check == true) {
            if (this.form.is_diamond == 0 && response.data.categoryId === 25) {
              this.$api.ShowAlert("info", this.$i18n.t("please select diamond permission"), '')
            }
            else if (this.form.is_diamond == 1 && response.data.categoryId < 25) {
              this.$api.ShowAlert("info", this.$i18n.t("this item not diamond"), '')
            }
            else {
              this.code = null;
              let newItem = response.data || Object
              newItem.barcode_id = response.data.id
              newItem.sub_category_id = response.data.subCategoryId
              newItem.weight = response.data.weight;
              newItem.gold_weight = response.data.gold_weight;
              newItem.caliber_id = response.data.caliber_id;
              newItem.clarity_id = response.data.clarity_id;
              newItem.shap_id = response.data.shap_id;
              newItem.quality_id = response.data.quality_id;
              newItem.color_id = response.data.color_id;
              newItem.stone_weight = response.data.stone_weight;
              newItem.min_sell_price = response.data.minPrice;
              newItem.purchase_cost = response.data.purchase_cost;
              newItem.package_weight = response.data.package_weight || 0;
              newItem.sell_price = response.data.price || 0;
              newItem.price = response.data.purchase_cost || 0;
              newItem.factor = response.data.factor || 0;
              newItem.supplier_code = response.data.supplier_code || 0;
              newItem.wages = 0;
              newItem.total_wages = 0;
              newItem.weight_in_24 = 0;
              newItem.pieces_count = +response.data.countPieces || 1;
              switch (newItem.categoryId) {
                case 25:
                  newItem.category_name = this.$i18n.t('diamond');
                  break;
                case 26:
                  newItem.category_name = this.$i18n.t('stone');
                  break;
                case 925:
                  newItem.category_name = this.$i18n.t('silver');
                  break;
                case 50:
                  newItem.category_name = this.$i18n.t('services');
                  break;
                default:
                  newItem.category_name = this.$i18n.t('caliber') + ' ' + newItem.categoryId;
                  newItem.weight_in_24 = this.$global.goldCalibration(newItem.categoryId, 24, response.data.weight);
                  break;
              }
              newItem.sub_category_name = newItem.itemTitle;
              newItem.category_id = newItem.categoryId;
              if (!this.tableRows[this.tableRows.length - 1]?.sub_category_id) {
                this.tableRows[this.tableRows.length - 1] = newItem;
                this.tableRows = [...this.tableRows]
              } else {
                this.tableRows.push(newItem)
              }
              this.addDetail();
            }
          } else {
            this.$api.ShowAlert("info", this.$i18n.t("No Results"), '')
          }
        })

      }
    }
  },
};
</script>
<style lang="scss" scoped></style>
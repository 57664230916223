<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000">
      <v-card relative>
        <v-btn absolute class="mx-2 mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="backValueMethod">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
        <v-card-title class="text-h6">
          {{ $t('sales invoice') }}
          <span class="mx-2 primary--text">{{ invoice_id || doc_code  }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="pa-5">
          <ControlSummery :summery="summery" :loading="isLoading" />

          <DynamicTable v-if="!edit && details.length" :isLoading="isLoading" :data="details" :showHeader="false"
            :tableHeight="$store.state.Settings.windowSize.y - 180" :header="tableHeader" cardClasses="shadow-none" />

          <v-col cols="12">
            <v-row v-if="edit">
              <GenericInput type="date" :maxDate="today" :dense="true" classes="rounded-lg" :value="invoice_date"
                @input="invoice_date = $event" label="invoice date" :required="false" :hide-details="true"
                :cols="[6, 6, 6]" />
              <GenericInput type="select" :lookups="paymentTypes" :value="payment_type_id"
                @input="payment_type_id = $event" label="payment type" :multi="false" :required="true"
                selected_label="name" selected_prop="id" :cols="[6, 6, 6]" />

              <GenericInput v-for="(payment, index) in payments" :key="index" type="float" classes="rounded-lg"
                :value="payment.amount" @input="payment.amount = $event" :label="payment.payment_title" :required="false"
                :hide-details="true" :cols="[6, 6, 6]" />

              <GenericInput type="select" :lookups="networkMachines" :value="machine_id" v-if="isNetwork"
                @input="machine_id = $event" label="machine" :multi="false" :required="true" selected_label="name"
                selected_prop="id" :cols="[6, 6, 6]" />

              <v-col cols="6" class="py-0 mb-2 justify-center d-flex" v-if="isNetwork">
                <v-btn depressed @click="cart_type = 3" text min-width="100" class="mx-2"
                  :class="cart_type == 3 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 3">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/mada.svg" height="20" alt="visa">
                </v-btn>
                <v-btn depressed @click="cart_type = 1" text min-width="100" class="mx-2"
                  :class="cart_type == 1 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 1">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/visa.svg" height="20" alt="visa">
                </v-btn>
                <v-btn depressed @click="cart_type = 2" text min-width="100" class="mx-2"
                  :class="cart_type == 2 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 2">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/mastercard.svg" height="30" alt="visa">
                </v-btn>

              </v-col>

            </v-row>


          </v-col>


          <v-col cols="12" class="d-flex justify-end">
            <v-btn :width="120" color="error" outlined class="d-block mx-2" @click="backValueMethod">
              {{ $t('close') }}
            </v-btn>
            <v-btn :width="120" color="primary" v-if="!edit && canEdit && zatcaCheck" class="d-block" @click="edit = true">
              {{ $t('edit') }}
            </v-btn>
            <v-btn :width="120" color="success" :disabled="!(+master.final_total <= +totalAmount)" v-if="edit && canEdit"
              :loading="saveIsLoading" class="d-block" @click="save">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>






<script>
import GenericInput from '@/components/GenericInput.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import ControlSummery from '@/components/ui/ControlSummery.vue';

export default {
  name: "SalesInvoiceModal",
  props: {
    backValueMethod: { default: Function },
    dialog: { default: false },
    doc_code: { default: null },
    invoice_id: { default: null },
    invoice: { default: Object },
    canEdit: { default: false },
  },
  computed: {
    totalAmount() {
      return this.payments.reduce((oldValue, newValue) => {
        return +oldValue + newValue.amount;
      }, 0)
    },
    zatcaCheck() {
      return !this.invoice?.zatca
    }
  },
  watch: {
    dialog() {
      if (this.dialog && this.invoice_id) {
        this.invoice_date = this.invoice.invoice_date
        this.getSalesInvoice()
      } else {
        this.details = [];
        this.edit = false;
        this.payment_type_id = null;
        this.cart_type = 3;
        this.machine_id = null;
      }
    },
    payment_type_id() {
      if (this.payment_type_id === 2 || this.payment_type_id === 3 || this.payment_type_id === 6) {
        this.isNetwork = true;
      } else {
        this.isNetwork = false
      }


      switch (this.payment_type_id) {
        case 1:
          this.payments = [
            {
              paymentType: 1,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'cash',
              amount: this.master.paidCash || 0
            }
          ]
          break;
        case 2:
          this.payments = [
            {
              paymentType: 2,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'bank',
              amount: this.master.paidBank || 0
            }
          ]
          break;
        case 3:
          this.payments = [
            {
              paymentType: 1,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'cash',
              amount: this.master.paidCash || 0
            },
            {
              paymentType: 2,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'bank',
              amount: this.master.paidBank || 0
            }
          ]
          break;
        case 4:
          this.payments = [
            {
              paymentType: 3,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'transfer',
              amount: this.master.paidTransfer || 0
            }
          ]
          break;
        case 5:
          this.payments = [
            {
              paymentType: 1,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'cash',
              amount: this.master.paidCash || 0
            },
            {
              paymentType: 1,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'transfer',
              amount: this.master.paidTransfer || 0
            }
          ]
          break;
        case 6:
          this.payments = [
            {
              paymentType: 2,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'bank',
              amount: this.master.paidBank || 0
            },
            {
              paymentType: 3,
              cart_type: this.cart_type,
              machineId: this.machine_id,
              payment_title: 'transfer',
              amount: this.master.paidTransfer || 0
            }
          ]
          break;

        default:
          this.payments = []
          break;
      }


    }
  },
  mounted() {
    this.paymentTypes = [
      {
        id: 1,
        name: this.$i18n.t("cash"),
      },
      {
        id: 2,
        name: this.$i18n.t("network"),
      },

      {
        id: 3,
        name: this.$i18n.t("cash and network"),
      },
      {
        id: 4,
        name: this.$i18n.t("transfer"),
      },
      {
        id: 5,
        name: this.$i18n.t("cash and transfer"),
      },
      {
        id: 6,
        name: this.$i18n.t("network and transfer"),
      },
    ];
    this.tableHeader = [
      { text: "item", key: "sub_category_title", type: 'text', classes: "" },
      { text: "category", key: "category_title", type: 'text', classes: "" },
      { text: "gold weight", key: "gold_weight", type: 'float', classes: "" },
      { text: "diamond weight", key: "diamond_weight", type: 'float', classes: "" },
      { text: "pieces count", key: "count_pieces", type: 'number', classes: "" },
      { text: "total vat", key: "total_vat", type: 'float', classes: "" },
      { text: "total without vat", key: "price", type: 'float', classes: "" },
      { text: "total with vat", key: "grand_total", type: 'float', classes: "" },
    ];
  },
  data: () => ({
      today: null,
      invoice_date: null,
      edit: false,
      isLoading: false,
      saveIsLoading: false,
      summery: [],
      paymentTypes: [],
      master: Object,
      details: [],
      tableHeader: [],
      cart_type: 3,
      payment_type_id: null,
      showMachines: null,
      isNetwork: null,
      machine_id: null,
      cardTypes: [],
      networkMachines: [],
      payments: [],
    }),
  methods: {
    getSalesInvoice() {
      this.isLoading = true;
      this.$api.GET_METHOD(`get_sales_invoice_by_qty/invoice?invoiceCode=${this.invoice_id}`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          if (response.data.payments.length) {
            this.invoice_date = response.data.master.invoice_date;
            response.data.master.paidCash = 0;
            response.data.master.paidBank = 0;
            response.data.master.paidTransfer = 0;
            response.data.payments.forEach(payment => {
              switch (payment.payment_type) {
                case 1:
                  response.data.master.paidCash = payment.amount || 0
                  break;
                case 2:
                  response.data.master.paidBank = payment.amount || 0
                  break;
                case 3:
                  response.data.master.paidTransfer = payment.amount || 0
                  break;
                default:
                  break;
              }
            });
          }
          this.summery = [
            {
              label: 'invoice date',
              value: response.data.master.invoice_date,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'branch',
              value: response.data.master.branch_name,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'customer',
              value: response.data.master.customer_name,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'sales agent',
              value: response.data.master.sales_agent_name,
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'total without vat',
              value: response.data.master.grand_total,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'total vat',
              value: response.data.master.grand_total_vat,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'cash',
              value: response.data.master.paidCash,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'network',
              value: response.data.master.paidBank,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'transfer',
              value: response.data.master.paidTransfer,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },

            {
              label: 'total with vat',
              value: response.data.master.final_total,
              type: 'float',
              class: '',
              cols: 6, sm: 6, md: 6, lg: 6, xl: 6,
            },
            {
              label: 'invoice description',
              value: response.data.master.description || '-',
              type: 'text',
              class: '',
              cols: 12, sm: 12, md: 12, lg: 12, xl: 12,
            },
          ]
          this.master = response.data.master || Object;

          this.networkMachines = response.data.machines || [];
          this.machine_id = response.data.machines.length ? response.data.machines[0].id : null;
          this.cardTypes = response.data.machines || [];
          this.details = response.data.details || [];
        } else {
          this.backValueMethod();
        }
      })
    },
    save() {

      if (this.payments.length && this.invoice_date) {
        this.saveIsLoading = true;
        this.payments.forEach(payment => {
          payment.machineId = this.machine_id;
          payment.cart_type = this.cart_type;
        });
        const requestBody = {
          invoice_date: this.invoice_date,
          payments: this.payments,
        }
        this.$api.POST_METHOD(`edit_sales_invoice/${this.invoice_id}`, requestBody).then((response) => {
          this.saveIsLoading = false;
          if (response.check) {
            this.backValueMethod();
          }
        })
      }

    },
  },
  components: { GenericInput, DynamicTable, ControlSummery }
};
</script>

<style scoped lang="scss"></style>
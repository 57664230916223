<template>
  <v-dialog v-model="dialog" persistent
    :max-width="((step == 1 && !chopping) || (step == 2 && chopping)) && discountsExample?.length && !diamondProperties ? 780 : 510">
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="closeDialog">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="d-flex justify-space-between py-0">
        <v-col cols="auto" class="px-0">
          <v-row>
            <v-col cols="auto" class="px-0">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto" class="px-0">
              <h6 class="gray6--text">{{ gold.itemTitle }}</h6>
              <h6 class="my-0">
                <strong v-if="gold.categoryId !== 25">{{ $t('caliber') }} {{ gold.categoryId }}</strong>
                <strong v-if="gold.categoryId == 25">{{ $t('caliber') }} {{ gold.caliber_id }}</strong>
                <strong v-if="gold.categoryId !== 25">- {{ $t('size') }} : {{ gold.size || 0 }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" v-if="gold.categoryId == 25">
          <v-row>
            <v-col cols="auto">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto">
              <h6 class="gray6--text">{{ $t('gold weight') }}</h6>
              <h6 class="my-0">
                <strong v-if="gold.categoryId == 25">{{ $global.DecimalFormatter(Number(gold.gold_weight).toFixed(2)) }}
                  {{ $t('gram') }}</strong>
                <strong v-if="gold.categoryId == 25"> - {{ $t('size') }} : {{ gold.size || 0 }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="px-0">
          <v-row>
            <v-col cols="auto">
              <v-icon small color="blue accent-4" left>mdi-check-circle</v-icon>
            </v-col>
            <v-col cols="auto">
              <h6 class="gray6--text">{{ $t('weight') }}</h6>
              <h6 class="my-0">
                <strong v-if="gold.categoryId !== 25">{{ +gold.weight | float }} {{ $t('gram') }}</strong>
                <strong v-if="gold.categoryId == 25">{{ +gold.diamond_weight | float }} {{ $t('qarat') }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
      <v-divider />
      <div class="pa-5 pt-0">
        <v-col cols="12" class="ma-auto"
          v-if="((step == 1 && !chopping) || (step == 2 && chopping)) && !diamondProperties">
          <v-row>
            <v-col :cols="discountsExample?.length ? 8 : 12">
              <div class="d-flex justify-space-between align-center">
                <h5 class="my-1">{{ $t(chopping ? 'please enter price after chopping' : 'pleaseEnterThePrice') }}</h5>
                <div class="text-end" v-if="!chopping">
                  <h5 class="my-1 gray6--text">{{ $t('priceOriginal') }} : {{ gold?.sell_cost | float }}
                    <v-tooltip bottom v-if="gold.minPrice">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="blue7" v-bind="attrs" size="14" v-on="on">mdi-information</v-icon>
                      </template>
                      <span>
                        {{ $t('lessPrice') }} : {{ gold.minPrice | float }}
                      </span>
                    </v-tooltip>
                  </h5>
                </div>
                <div v-else>
                  {{ $t('priceOriginal') }} : {{ priceOriginalComputed | float }}
                  <v-tooltip bottom v-if="+gold.categoryId <= 24 && +gold.categoryId >= 18">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="blue7" v-bind="attrs" v-on="on">mdi-information</v-icon>
                    </template>
                    <span>
                      {{ $t('lessPrice') }} : {{ minLessPriceComputed | float }}
                      <!-- {{ (+coppingWeight * +gold_price[`price_${+gold.categoryId}`]) * (1 + vats[+gold.categoryId]) | float }} -->
                    </span>
                  </v-tooltip>
                </div>
              </div>
              <NumberPad :backValueMethod="setPrice" :clickEnter="setData" :value="price" />
            </v-col>
            <v-col v-if="discountsExample.length" :cols="4">
              <h5 class="my-1 gray1--text">{{ $t('available discounts') }}: </h5>
              <v-simple-table dense>
                <thead>
                  <tr>
                    <th class="text-center">{{ $t('percent') }}</th>
                    <th class="text-center" colspan="2">{{ $t('value') }}</th>
                  </tr>
                </thead>
                <tbody v-if="costIdentityLoading">
                  <tr>
                    <td class="text-center font-weight-bold primary--text" colspan="3">{{ $t('loading please wait') }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="(discount, index) in discountsExample" :key="index">
                    <td class="text-center">{{ discount.percent }}%</td>
                    <td class="text-center">{{ discount.amount | float }}</td>
                    <td class="text-center">
                      <v-btn color="success" @click="selectDiscount(discount)"
                        icon><v-icon>mdi-check-circle</v-icon></v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>

        <!-- Diamond Additions -->
        <v-col cols="12" sm="11" class="ma-auto" v-if="gold.categoryId == 25 && diamondProperties">
          <v-row align="center" justify="end">
            <v-col cols="6">
              <label class="mx-2 body-2">{{ $t('clarity') }}</label>
              <v-select :items="clarities" dense v-model="summary.clarity_id" background-color="#E8E8EE" hide-details
                class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'clarity_code'"
                :item-value="'clarity_id'" solo></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <label class="mx-2 body-2">{{ $t('quality') }}</label>
              <v-select :items="qualities" dense v-model="summary.quality_id" background-color="#E8E8EE" hide-details
                class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'title'" :item-value="'id'" solo>
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <label class="mx-2 body-2">{{ $t('color') }}</label>
              <v-select :items="colors" dense v-model="summary.color_id" background-color="#E8E8EE" hide-details
                class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'color_code'" :item-value="'color_id'"
                solo></v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <label class="mx-2 body-2">{{ $t('shape') }}</label>
              <v-select :items="shapes" dense v-model="summary.shap_id" background-color="#E8E8EE" hide-details
                class="my-2 rounded grey lighten-5 box-shadow-none" :item-text="'title'" :item-value="'id'" solo>
              </v-select>
            </v-col>
            <v-col cols="6" class="py-0">
              <label class="mx-2 body-2">{{ $t('stone type') }}</label>
              <v-text-field dense v-model="summary.stone_type" background-color="#E8E8EE" hide-details
                class="my-2 rounded grey lighten-5 box-shadow-none" solo>
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <label class="mx-2 body-2">{{ $t('stone weight') }}</label>
              <GenericInput type="float" :outlined="false" :solo="true" :value="summary.stone_weight"
                background="#E8E8EE" @input="stone_weight = $event" label="" :required="true" paddingX="px-0"
                classes="rounded grey lighten-5 box-shadow-none" :isLoading="false" :cols="[12, 12, 12]" />
            </v-col>

            <v-col cols="6"></v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="11" class="ma-auto" v-if="step == 1 && chopping && gold.package_items.length">
          <div class="subtitle-1 primary--text font-weight-bold text-center">{{ $t('select package items') }}</div>
          <v-card class="shadow mt-2">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t('') }}</th>
                    <th>{{ $t('barcode') }}</th>
                    <th>{{ $t('item') }}</th>
                    <th>{{ $t('weight') }}</th>
                    <th>{{ $t('price') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in package_items" :key="index">
                    <td> <v-switch @change="package_items = [...package_items]" v-model="item.checked" class="mt-n2"
                        lazy color="blue4" hide-details inset label="" /></td>
                    <td>{{ item.id }}</td>
                    <td class="text-center">{{ item.itemTitle }} <br /> {{ (item.categoryId == 25 ? $t('diamond')
                      : $t('caliber') + ' ' +
                      item.categoryId) }}</td>
                    <td>{{ item.categoryId == 25 ? item.diamond_weight : item.gold_weight || item.weight | float }}</td>
                    <td class="text-center align-center justify-center d-flex" width="115px">
                      <span class="me-2" v-if="item.price && item.checked">{{
                        item.price | float }}</span>
                      <v-btn @click="() => { priceDialogPad = true; selectedItemIndex = index }" v-if="item.checked"
                        icon small :color="item.checked && item.price ? 'blue2' : 'success'">
                        <v-icon>{{ item.price ? 'mdi-pencil-circle' : 'mdi-plus-circle' }}</v-icon>
                      </v-btn>

                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" class="ma-auto" v-if="step == 1 && chopping && !gold.package_items.length">
          <div class="d-flex justify-space-between align-center">
            <h5 class="my-1">{{ $t('please enter chopping weight') }}</h5>
            <div class="text-end">
              <h5 class="my-1 text--disabled">
                {{ $t('maximium chopping weight') }} : <br /> {{ maxCoppingWeight | float }} {{ $t('gram') }}
              </h5>
            </div>
          </div>
          <NumberPad :backValueMethod="setCopping" :clickEnter="setData" :value="coppingWeight.toString()" />

        </v-col>

        <v-col cols="12" class="py-0 ma-auto d-flex justify-end align-center">
          <v-btn color="error" text v-if="chopping && step > 1" min-width="120" large
            @click="() => { chopping = false; step-- }" class=" me-2">
            {{ $t('back') }}</v-btn>

          <v-btn color="primary" v-if="gold.categoryId !== 50 && !chopping" min-width="120" large
            @click="() => { chopping = true; step = 1 }" class="shadow me-2">
            {{ $t('partial sale') }} / {{ $t('chopping') }} {{ $t('item') }}</v-btn>

          <v-btn color="success" v-if="chopping && gold.package_items.length && step == 1" large
            :disabled="!(package_items.length && selectedItemsPack.every(item => item.price > 0) || (this.customerType == 2 && selectedItemsPack.length))"
            min-width="120" @click="addItems" class="shadow">
            {{ $t('ok') }}</v-btn>

          <v-btn color="success" v-if="chopping && step == 1 && !gold.package_items.length" large
            :disabled="!(maxCoppingWeight >= coppingWeight && coppingWeight)" min-width="120" @click="step++"
            class="shadow">
            {{ $t('next') }}</v-btn>

          <template v-if="!chopping || step == 2">

            <v-btn color="success" v-if="gold.categoryId !== 25" large :disabled="!valid" min-width="120"
              @click="() => summaryDialog = true" class="shadow">
              {{ $t('ok') }}</v-btn>

            <v-btn color="success" v-else large :disabled="!valid" min-width="120" @click="setDiamondProp"
              class="shadow">
              {{ diamondProperties ? $t('ok') : $t('next') }}</v-btn>
          </template>
        </v-col>
      </div>
    </v-card>

    <v-dialog v-model="priceDialogPad" v-if="package_items.length" persistent max-width="510">
      <v-card class="pa-9" flat>
        <v-btn absolute class="mt-n5" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
          color="grey lighten-1" text @click="priceDialogPad = false">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>

        <v-row align="center">
          <v-col cols="6" class="py-3">
            <h5 class="subtitle-1 font-weight-bold mb-5">
              {{ $t('chopping') }} {{ package_items[selectedItemIndex].itemTitle }} {{ $t('from') }} {{
                gold.itemTitle }}
            </h5>
          </v-col>
          <v-col cols="6" class="py-3">
            <div class="d-flex justify-space-between align-center light pa-2 rounded-lg shadow">
              <div>
                <h5>
                  {{ $t('minimum price') }}:
                  <div class="gray6--text">{{ package_items[selectedItemIndex].minPrice | float }}</div>
                </h5>
                <h5>
                  {{ $t('priceOriginal') }}:
                  <div class="gray6--text">{{ package_items[selectedItemIndex].price | float }}</div>
                </h5>
              </div>
            </div>
          </v-col>
        </v-row>


        <h5 class="body-2 font-weight-bold mb-5">{{ $t('please enter price') }}
          ( {{ package_items[selectedItemIndex].itemTitle }} )
        </h5>
        <NumberPad :backValueMethod="setDetailPrice" :value="String(package_items[selectedItemIndex].price)" />
        <v-btn color="success"
          :disabled="!((package_items[selectedItemIndex].price >= package_items[selectedItemIndex].minPrice) || this.customerType == 2)"
          class="d-block mi-start-auto" @click="setDetail" min-width="120">
          {{ $t('ok') }}
        </v-btn>
      </v-card>
    </v-dialog>
    <InvoiceSummery :backValueMethod="setData" :closeMethod="() => { summaryDialog = false }" :dialog="summaryDialog"
      :summery="summary" :loading="loading"></InvoiceSummery>
  </v-dialog>
</template>

<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
import InvoiceSummery from "./InvoiceSummery.vue";
import GenericInput from "../GenericInput.vue";
export default {
  name: "ItemPieceModal",
  props: {
    closeDialog: { type: Function },
    gold: { default: {} },
    gold_price: { default: {} },
    package_items: { default: [] },
    sub_categories: { default: [] },
    dialog: { default: false },
    vats: { default: [] },
    code: { default: '' },
    clarities: { default: [] },
    qualities: { default: [] },
    shapes: { default: [] },
    colors: { default: [] },
    cost_identity_details: { default: [] },
    costIdentityLoading: { default: false },
  },
  computed: {
    ...mapState(["bill", "customerType"]),
    valid() {
      if (this.chopping) {
        if (this.maxCoppingWeight >= +this.coppingWeight && +this.coppingWeight !== 0 && this.choppingPriceValid) {
          return true;
        } else {
          return false;
        }
      }
      else {
        if (this.customerType == 1 && this.$store.state.userData.monitor_sales_price) {
          if (+this.price >= +this.gold.minPrice) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    },
    choppingPriceValid() {
      if (this.chopping && this.customerType == 1) {
        if (+this.gold.categoryId <= 24 && +this.gold.categoryId >= 18) {
          let vat = +this.vats[+this.gold.categoryId]
          let gold_price = this.gold_price[`price_${+this.gold.categoryId}`]
          let minPrice = (+this.coppingWeight * +gold_price) * (1 + vat)
          return +this.price >= +minPrice
        } else {
          return +this.price > 0;
        }
      } else {
        return +this.price > 0;
      }
    },
    maxCoppingWeight() {
      switch (+this.gold.categoryId) {
        case 25:
          return +this.gold.diamond_weight - 0.01;
        case 26:
          return +this.gold.stone_weight - 0.01;
        case 925:
          return +this.gold.silver_weight - 0.01;
        default:
          return +this.gold.gold_weight - 0.01;
      }
    },
    selectedItemsPack() {
      return this.package_items?.filter(item => item.checked) || []
    },
    costIdentityDetail() {
      let item_cost_identity = this.cost_identity_details.filter(({ category_id, m_id, is_default }) => this.gold.cost_identity_m_id ? m_id == this.gold.cost_identity_m_id : category_id == this.gold.categoryId && is_default)
      return item_cost_identity.find(({ weight_from, weight_to }) => this.gold.weight >= weight_from && +this.gold.weight <= weight_to) || {}
    },
    discountsExample() {
      let cost_discounts = this.costIdentityDetail?.discount_percent?.split(',')?.map(i => +i) || []
      let discounts = []
      cost_discounts.forEach(discount => {
        discounts.push({
          percent: discount,
          amount: this.gold.price * ((100 - discount) / 100),
        })
      });
      return discounts
    },
    priceOriginalComputed() {
      const priceWithOutVat = (+this.gold_price[`price_${+this.gold.categoryId}`] + +this.gold.purchase_cost + +this.gold.sell_cost) * this.coppingWeight
      const vat = this.gold.categoryId == 24 ? 0 : priceWithOutVat * (this.vats[+this.gold.categoryId])
      return priceWithOutVat + vat
    },
    minLessPriceComputed() {
      const priceWithOutVat = (+this.gold_price[`price_${+this.gold.categoryId}`] + +this.gold.purchase_cost + +this.gold.min_sell_cost) * this.coppingWeight
      const vat = this.gold.categoryId == 24 ? 0 : priceWithOutVat * (this.vats[+this.gold.categoryId])
      return priceWithOutVat + vat
    }
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.summaryDialog = false;
        this.loading = false;
        this.chopping = false;
        this.priceDialogPad = false;
        this.diamondProperties = false;
        this.step = 1;
        this.selectedID = {};
        this.price = "";
        this.coppingWeight = "";
        this.itemPriceWithoutVat = 0;
        this.itemVat = 0;
        this.selectedItemIndex = 0;
        this.selected_item_package = [];
        this.summary = {};
      } else {
        // if (!this.gold.is_locked) {
        //   this.selected_item_package = this.package_items.map((item) => item.item_code)
        // }
      }
    }
  },
  components: {
    NumberPad,
    InvoiceSummery,
    GenericInput
  },
  methods: {
    ...mapActions(["addBillAction"]),

    setPrice(returnedPrice) {
      this.price = returnedPrice;
      this.itemPriceWithoutVat = Number(returnedPrice) / (1 + Number(this.vats[this.gold.categoryId]));
      this.itemVat = Number(returnedPrice) - this.itemPriceWithoutVat;
      this.summary = {
        item_code: this.gold.id,
        itemTitle: this.gold.itemTitle,
        categoryId: this.gold.categoryId,
        subCategoryId: this.gold.subCategoryId,
        gold_weight: Number(this.gold.gold_weight) || 0,
        diamond_weight: Number(this.gold.diamond_weight) || 0,
        stone_weight: Number(this.gold.stone_weight) || 0,
        silver_weight: Number(this.gold.silver_weight) || 0,
        minPrice: Number(this.gold.minPrice),
        priceWithoutVat: Number(this.gold.priceWithoutVat) || this.itemPriceWithoutVat,
        vat: Number(this.itemVat),
        price: Number(returnedPrice),
        countPieces: this.gold.countPieces,
        shap_id: this.gold.shap_id,
        quality_id: this.gold.quality_id,
        clarity_id: this.gold.clarity_id,
        stone_id: this.gold.stone_id,
        color_id: this.gold.color_id,
        count_diamonds: this.gold.count_diamonds,
        caliber_id: this.gold.caliber_id,
        parent_item_code: this.gold.parent_item_code,
        caliber_price: this.gold.caliber_price || 0,
        lobes: null,
      }
    },
    setCopping(weight) {
      this.coppingWeight = +weight;
      this.summary.coppingWeight = +weight;
    },
    setData(status) {
      if (status == true && this.valid) {
        this.summaryDialog = false;
        this.setPrice(this.price);
        if (this.chopping) {
          this.summary.item_code = 0;
          this.summary.parent_item_code = this.gold.id;
          switch (+this.gold.categoryId) {
            case 25:
              this.summary.diamond_weight = +this.coppingWeight;
              break;
            case 26:
              this.summary.stone_weight = +this.coppingWeight;
              break;
            case 925:
              this.summary.silver_weight = +this.coppingWeight;
              break;
            default:
              this.summary.gold_weight = +this.coppingWeight;
              break;
          }
        }
        this.addBillAction(this.summary).finally(() => {
          this.summaryDialog = false;
          this.chopping = false;
          this.step = 1;
          this.coppingWeight = '';
          this.closeDialog();
        });

      } else {
        this.summaryDialog = false
      }
    },
    addItems() {
      this.selectedItemsPack.forEach((item, index) => {
        item.totalPriceWithVat = +item.price / (1 + this.vats[item.categoryId])
        item.totalVat = +item.price - (+item.price / (1 + this.vats[item.categoryId]));
        let neItem = {
          parent_item_code: this.gold.id || this.gold.parent_item_code,
          item_code: item.id,
          itemTitle: item.itemTitle + ` (${this.$t('chopping')})`,
          categoryId: item.categoryId,
          caliber_price: item.caliber_price || 0,
          subCategoryId: item.subCategoryId,
          gold_weight: +item.gold_weight || 0,
          diamond_weight: +item.diamond_weight || 0,
          stone_weight: +item.stone_weight || 0,
          silver_weight: +item.silver_weight || 0,
          minPrice: +item.minPrice,
          priceWithoutVat: +item.price,
          vat: +item.totalVat,
          price: +item.price,
          countPieces: item.countPieces || 1,
          shap_id: item.shap_id,
          quality_id: item.quality_id,
          clarity_id: item.clarity_id,
          stone_id: item.stone_id,
          color_id: item.color_id,
          count_diamonds: item.count_diamonds,
          caliber_id: item.caliber_id,
        }
        this.addBillAction(neItem).finally(() => {
          if (this.selectedItemsPack.length == index + 1) {
            this.summaryDialog = false;
            this.chopping = false;
            this.diamondProperties = false;
            this.step = 1;
            this.coppingWeight = '';
            this.package_items = []
            this.closeDialog();
          }
        });
      });
    },
    setDetail() {
      this.priceDialogPad = false;
    },
    setDetailPrice(value) {
      this.package_items[this.selectedItemIndex].price = +value || this.package_items[this.selectedItemIndex].price;
    },
    setDiamondProp() {
      if (this.diamondProperties) {
        this.summaryDialog = true
      } else {
        this.diamondProperties = true
      }
    },
    selectDiscount(discount) {
      this.setPrice(String(discount.amount));
      this.addBillAction(this.summary).finally(() => {
        this.summaryDialog = false;
        this.chopping = false;
        this.step = 1;
        this.coppingWeight = '';
        this.closeDialog();
      });
    }
  },


  data() {
    return {
      summaryDialog: false,
      loading: false,
      chopping: false,
      priceDialogPad: false,
      diamondProperties: false,
      step: 1,
      selectedID: {},
      price: "",
      coppingWeight: "",
      itemPriceWithoutVat: 0,
      itemVat: 0,
      selectedItemIndex: 0,
      selected_item_package: [],
      summary: {}
    };
  },
};
</script>

<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" class="d-flex justify-center align-center py-0 mb-6">
          <v-row align="center" justify="space-between">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center me-3 text--disabled">
                <strong>{{ $global.FilterPermissions("06-028").sub_title }} .
                  {{ form.id ? $t('edit') : $t('add') }}</strong>
              </div>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
                {{ $t('cancel') }}
                <v-icon right>mdi-close</v-icon>
              </v-btn>
              <v-btn color="success" :min-width="120" :disabled="!(valid && Number(this.form.amount))"
                :loading="saveIsLoading" depressed @click="save">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-card class="shadow">

        <v-row justify="center" v-if="isLoading">
          <v-col cols="auto">
            <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
              indeterminate />
            <p class="text-center">{{ $t('Loading data') }}</p>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="!isLoading">
          <v-sheet class="backgroundW py-0 pa-5">
            <v-row justify="space-between" align="center" class="justify-xl-space-around py-4 py-xl-6">

              <v-col cols="12" md="4" lg="4" xl="3">
                <v-sheet max-width="400" class="d-block mt-n3 ma-auto">
                  <label>{{ $t('amount') }}*</label>
                  <NumberPad :backValueMethod="setAmount" :value="String(form.amount)" />
                </v-sheet>
              </v-col>

              <v-col cols="auto" class="d-none py-16 d-md-block">
                <v-divider vertical style="height: 220px ;"></v-divider>
              </v-col>
              <v-col cols="12" md="7" lg="7" xl="8">
                <v-row>
                  <v-col cols="12" md="6" class="py-4">
                    <!-- Action Date -->
                    <GenericInput type="date" :solo="false" :dense="true" classes="rounded-lg" :value="form.action_date"
                      @input="form.action_date = $event" label="date" :required="true" :hide-details="true"
                      :cols="[12, 12, 12]" />

                    <!-- Customer -->
                    <GenericInput type="async" :solo="false" :lookups="customers" searchEndPoint="search_customer?word="
                      :value="form.from_customer_id" @input="form.from_customer_id = $event"
                      @filteredLookups="customers = $event" :label="form.customer_name || 'customer'" :multi="false"
                      :required="true" :selected_label="`name,phone`" placeholder="enter customer name, phone"
                      selected_prop="id" :hide-details="true" :isLoading="false" :cols="[12, 12, 12]" />

                    <!-- Payment Types -->
                    <GenericInput type="select" :lookups="payment_types" :value="form.to_type"
                      @input="form.to_type = $event" label="payment type" :multi="false" :required="true"
                      selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                      :cols="[12, 12, 12]" />

                    <!-- Treasuries -->
                    <GenericInput type="select" v-if="form.to_type == 2" :lookups="treasuries"
                      :value="form.to_treasury_id" @input="form.to_treasury_id = $event" label="treasury" :multi="false"
                      :required="true" selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                      :cols="[12, 12, 12]" />

                    <!-- Machines -->
                    <GenericInput type="select" v-else-if="form.to_type == 1" :lookups="networkMachines"
                      :value="form.machine_id" @input="form.machine_id = $event" label="machine" :multi="false"
                      :required="true" selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                      :cols="[12, 12, 12]" />

                    <!-- BANKS -->
                    <GenericInput type="select" v-if="form.to_type == 1 || form.to_type == 3" :lookups="banks"
                      :allowInput="form.is_closed !== 3" :value="form.bank_id" @input="form.bank_id = $event"
                      label="bank" :multi="false" :required="true" selected_label="name" selected_prop="id"
                      :cols="[12, 12, 12]" />

                  </v-col>
                  <v-col cols="12" md="6">
                    <GenericInput type="textarea" :solo="false" :dense="true" :rowsNumber="9" classes="rounded-lg"
                      :value="form.description" @input="form.description = $event" label="description" :required="false"
                      :hide-details="true" :cols="[12, 12, 12]" />
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-sheet>
        </v-form>

      </v-card>
      <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/deposits" />
    </v-container>
  </section>
</template>



<script>
import NumberPad from "@/components/NumberPad.vue";
import GenericInput from "@/components/GenericInput.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
export default {
  name: "SalesDepositsCreate",
  computed: {},
  data: () => ({
    valid: false,
    isLoading: false,
    saveIsLoading: false,
    exitDialog: false,
    networkMachines: [],
    treasuries: [],
    payment_types: [],
    collectionsMethods: [],
    customers: [],
    banks: [],
    key: 1,
    form: {
      action_date: null,
      from_customer_id: null,
      payment_title: 'cash',
      to_type: null,
      cart_type: 0,
      machine_id: 0,
      bank_id: 0,
      to_treasury_id: 0,
      amount: 0,
      description: null,
    },
  }),
  components: {
    NumberPad,
    GenericInput,
    ExitConfirmationModal
  },
  watch: {},
  mounted() {
    this.form.action_date = this.$global.GetCurrentDate();
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api
        .GET_METHOD(`sales_deposit/create`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.form.to_type = 1;
            this.treasuries = response.data.treasures || [];
            this.banks = response.data.banks || [];
            this.networkMachines = response.data.machines || [];
            this.collectionsMethods = [
              { id: 1, name: this.$t("credit"), },
              { id: 2, name: this.$t("debit") },
            ];
            this.payment_types = [
              { id: 1, name: this.$t("network"), },
              { id: 2, name: this.$t("cash"), },
              { id: 3, name: this.$t("transfer") },
            ];
            if (this.$route.params.master) {
              this.$route.params.master.to_type = +this.$route.params.master.to_type
              this.form = this.$route.params.master || this.form;
              this.customers.push({ id: this.$route.params.master.from_customer_id, name: this.$route.params.master.customer_name })
            }
          }
        });
    },
    save() {
      this.saveIsLoading = true;
      this.form.id ? this.form._method = 'PUT' : '';
      this.$api.POST_METHOD(`sales_deposit${this.form.id ? '/' + this.form.id : ''}`, this.form, true).then((response) => {
        this.saveIsLoading = false;
        if (response.check) {
          this.$router.push('/sales_deposit')
        }
      })
    },
    setAmount(returnedAmount) {
      this.form.amount = +returnedAmount;
    },

  },
};
</script>
